<template>
  <div v-if="box">
    <CCard v-if="!lodash.isEmpty(box)">
      <CCardHeader class="d-flex align-items-center">
        <TMessage content="Goods" capitalize bold />
        <CBadge :color="box.closed_at ? 'success' : 'danger'" class="ml-2">
          <TMessage :content="box.closed_at ? 'Closed box' : 'Open box'" />
        </CBadge>
        <TMessageDateTime
          v-if="box.closed_at"
          :content="box.closed_at"
          dateOnly
          class="ml-2"
        />
        <div class="d-flex ml-auto" v-if="creatable && box.items">
          <TButton
            v-if="!box.closed_at && box.items.length"
            content="Add item"
            icon="cil-plus"
            variant="outline"
            class="ml-2 px-3"
            @click="showModalAddItem = true"
          />
          <SModalAddItemSku
            :show.sync="showModalAddItem"
            @created="$emit('updated')"
            :box_id="box.id"
          />
          <TButton
            v-if="box.items && box.items.length"
            :content="box.closed_at ? 'Open' : 'Packed'"
            :icon="box.closed_at ? 'cilLockUnlocked' : 'cilLockLocked'"
            :color="box.closed_at ? 'danger' : 'success'"
            class="ml-2 px-3"
            :options="{ disabled: loadingBox }"
            @click="box.closed_at ? openBox() : closeBox()"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <div class="mt-3" v-for="item in box.items" :key="item.product_id">
          <CRow style="w-100">
            <CCol col="5">
              <SCardProductItems
                widthAuto
                :item="item.product"
                resource="/warehouse/management/products"
              />
            </CCol>
            <CCol col="3">
              <TMessage content="Items in box" />
              <TMessageNumber
                :editable="editable && !box.closed_at"
                @change="updateItemInBox('quantity', item.id, $event)"
                :value="item.quantity"
                bold
              />
            </CCol>
            <CCol col="3">
              <TMessage content="Expiration date" />
              <TMessageDateTime
                :editable="editable && !box.closed_at"
                @change="updateItemInBox('expiry_date', item.id, $event)"
                :content="item.expiry_date"
                dateOnly
              />
            </CCol>
            <CCol>
              <TButtonDelete
                v-if="removable && !box.closed_at"
                variant="outline"
                @click="removeItemInBox(item.id)"
              />
            </CCol>
          </CRow>
        </div>
        <div v-if="box.items && !box.items.length">
          <div class="d-flex" v-if="!readOnly">
            <SSelectProduct
              :id.sync="product_id"
              @update:id="updateItem"
              :key="selectProductKey"
              class="w-100"
              :showItem="false"
              placeholder="Add product"
              @keydown.enter.native="add"
            />
            <TButton
              content="Create new"
              icon="cil-plus"
              variant="outline"
              class="ml-2 px-1 w-25"
              @click="showModalCreateProduct = true"
            />
          </div>
          <div v-for="item in items" :key="item.product_id" class="mt-3">
            <CRow style="w-100">
              <CCol col="5">
                <SCardProductItems
                  widthAuto
                  :item="item.product"
                  resource="/warehouse/management/products"
                />
              </CCol>
              <CCol col="3">
                <TInputNumber
                  label="Items in box"
                  :value.sync="item.quantity"
                />
              </CCol>
              <CCol col="3">
                <TInputDateTime
                  label="Expiration date"
                  :value.sync="item.expiry_date"
                />
              </CCol>
              <CCol class="my-auto">
                <TButtonRemove
                  noConfirm
                  variant="outline"
                  @click="removeItem(item.product_id)"
                />
              </CCol>
            </CRow>
          </div>
        </div>
        <TMessageNoData v-if="box.items && !box.items.length && readOnly" />
      </CCardBody>
      <CCardFooter v-if="box.items && !box.items.length && items.length">
        <TButton
          class="mx-auto d-flex p-2 px-4"
          icon="cis-box"
          content="Packed"
          size="lg"
          @click="packed"
        />
      </CCardFooter>
    </CCard>
    <SCardCreateProduct
      mode="quicknew"
      :show.sync="showModalCreateProduct"
      :product_id="productIdCreateNew"
      @created="productCreated"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import label from "../mixins/label";
import audio from "../mixins/audio";
export default {
  mixins: [label, audio],
  props: {
    box: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      product_id: "",
      product: {},
      showModalAddItem: false,
      inputSearchProduct: "",
      productIdCreateNew: "",
      showModalCreateProduct: false,
      selectProductKey: "",
    };
  },
  mounted() {
    window.addEventListener("keypress", this.searchProducts);
  },
  destroyed() {
    window.removeEventListener("keypress", this.searchProducts);
  },
  computed: {
    ...mapGetters({
      loadingBox: "warehouse.boxes_management.loading",
    }),
  },
  methods: {
    removeItemInBox(id) {
      this.$store.dispatch("warehouse.box_items.delete", id).then((data) => {
        this.$emit("updated", data);
      });
    },
    updateItemInBox(field, id, value) {
      this.$store
        .dispatch("warehouse.box_items.update", {
          id: id,
          attributes: {
            [field]: value,
          },
        })
        .then((data) => {
          this.$emit("updated", data);
        });
    },
    removeItem(id) {
      const itemIndex = this.items.findIndex((x) => x.product_id === id);
      this.items.splice(itemIndex, 1);
    },
    openBox() {
      this.$store
        .dispatch("warehouse.boxes_management.open", this.box.id)
        .then(() => {
          this.$emit("updated");
        });
    },
    closeBox() {
      this.$store
        .dispatch("warehouse.boxes_management.close", this.box.id)
        .then(() => {
          this.$emit("updated");
          const message = {
            ...this.message,
            type: "SKU",
            sfaId: this.box.sfa_id,
            sku: this.box.id,
            quantity: this.box.duplicate,
            itemQuantity: this.box?.items.length,
            pageSize: "1", Barcode: "1", map: "1", place: "2", region: "Chiba",
          };
          this.$store.dispatch("printer.send-message", JSON.stringify(message));
        });
    },
    add() {
      const itemIndex = this.items.findIndex(
        (x) => x.product_id == this.product_id
      );
      if (!this.product_id) return;
      if (itemIndex == -1) {
        this.items.push({
          product: this.product,
          product_id: this.product_id,
          quantity: this.product.quantity_items_in_box || 1,
          expiry_date: null,
        });
        this.product_id = "";
      } else {
        this.items[itemIndex].quantity += 1;
        this.product_id = "";
      }
    },
    packed() {
      let data = {
        box_id: this.box.id,
      };
      this.items.forEach((x, index) => {
        // Remove keys
        let obj = this.lodash.omit(x, ["product"]);
        const item = this.lodash.mapKeys(obj, (value, key) => {
          return `products[${index}][${key}]`;
        });
        data = { ...item, ...data };
      });
      this.$store
        .dispatch(
          "warehouse.box_items.create",
          this.lodash.pickBy(data, this.lodash.identity)
        )
        .then((data) => {
          this.$store
            .dispatch("warehouse.boxes_management.close", this.box.id)
            .then(() => {
              this.$emit("updated", data);
            });
          this.items = [];
          const message = {
            ...this.message,
            type: "SKU",
            sfaId: this.box.sfa_id,
            sku: this.box.id,
            quantity: this.box.duplicate,
            itemQuantity: data.length,
            pageSize: "1", Barcode: "1", map: "1", place: "2", region: "Chiba",
          };
          this.$store.dispatch("printer.send-message", JSON.stringify(message));
        });
    },
    updateItem(id, product) {
      this.product_id = id;
      this.product = product;

      this.add();
      this.selectProductKey = this.lodash.resetKey("select-product");
    },
    productCreated(data) {
      this.updateItem(data.id, data);
      this.productIdCreateNew = "";
    },
    searchProducts(e) {
      if (
        this.showModalCreateProduct ||
        this.box.closed_at ||
        this.box.items.length ||
        this.disabled
      )
        return;
      if (e.target.tagName == "INPUT") return;
      if (e.key == "Enter") {
        if (!this.inputSearchProduct) return;
        const input = this.inputSearchProduct;
        const itemExist = this.items.find((x) => x.product_id == input);
        if (this.inputSearchProduct && !itemExist) {
          this.$tomoni.product.products_search
            .all({ q: input })
            .then(({ data }) => {
              const result = data.data.find((x) => input == x.id);
              if (result) {
                this.updateItem(result.id, result);
                this.playSuccess();
              } else {
                this.productIdCreateNew = input;
                this.showModalCreateProduct = true;
                this.playFail();
              }
            });
        } else {
          let item;
          if (itemExist) item = itemExist;
          this.updateItem(item.product_id, item.product);
          this.playSuccess();
        }
        this.inputSearchProduct = "";
        return;
      }
      this.inputSearchProduct += e.key;
    },
  },
};
</script>
