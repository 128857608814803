<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem class="cursor-pointer p-0" color="success">
            <TMessage
              @click.native="checkSFA"
              class="p-2 py-3"
              content="Import"
              bold
              alignment="center"
            >
              <template #prefix>
                <CIcon name="cil-plus" class="mr-1" />
              </template>
            </TMessage>
          </CListGroupItem>
          <CListGroupItem>
            <FilterForSfa :queries.sync="query" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(sfa, index) in sfas"
            :key="`${index}${sfa.id}`"
            :color="sfa_id_selected == sfa.id ? 'primary' : 'secondary'"
          >
            <SCardSfa
              :sfa="sfa"
              @click.native="checkSwapSFA(sfa)"
              class="cursor-pointer"
            />
          </CListGroupItem>
          <CListGroupItem v-if="!sfas.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="list_fetching">
            <TSpinner :loading="list_fetching" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.sfas" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <div v-if="sfa_id_selected">
        <CCard>
          <CCardHeader class="d-flex align-items-center">
            <div class="d-flex">
              <TMessage content="SFA" bold>
                <template #suffix
                  >:
                  {{ sfa_id_selected }}
                </template>
              </TMessage>
              <TSpinner :loading="detail_loading" />
            </div>
            <SButtonAssembleToPallets
              :sfa="sfa_selected"
              :remaining_quantity="sfa_selected.quantity - boxes_quantity"
              class="ml-auto"
            />
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="5">
                <TTableAsForm
                  :fields="sfaFields"
                  :data="sfa_selected"
                  style="font-size: 16px"
                >
                  <template #arrival_date="{ value }">
                    <TMessageDateTime
                      dateOnly
                      :content="value"
                      editable
                      @change="updateSFA('arrival_date', $event)"
                    />
                  </template>
                  <template #coupon="{ value }">
                    <TMessageText
                      :value="value"
                      editable
                      @change="updateSFA('coupon', $event)"
                    />
                  </template>
                  <template #shipping_inside="{ value }">
                    <TMessageMoney
                      :amount="value"
                      editable
                      :currency="sfa_selected.currency_id || 'JPY'"
                      @change="updateSFA('shipping_inside', $event)"
                    />
                  </template>
                  <template #quantity="{ value }">
                    <TMessageNumber
                      :value="value"
                      editable
                      @change="updateSFA('quantity', $event)"
                    />
                  </template>
                  <template #tracking_code="{ value }">
                    <TMessageText
                      :value="value"
                      editable
                      @change="updateSFA('tracking_code', $event)"
                    />
                  </template>
                </TTableAsForm>
              </CCol>
              <CCol md="7">
                <div class="scroll-x inline-flex text-nowrap">
                  <VueFileAgent
                    ref="vueFileAgent"
                    :theme="'default'"
                    :multiple="true"
                    :deletable="true"
                    :meta="true"
                    :linkable="true"
                    :accept="'image/*'"
                    :maxSize="'15MB'"
                    :maxFiles="3"
                    :helpText="$t('Upload receipts')"
                    :errorText="{
                      type: 'Invalid file type',
                      size: 'Files should not exceed 15MB in size',
                    }"
                    @select="filesSelected($event)"
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)"
                    v-model="fileRecords"
                  ></VueFileAgent>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CRow class="d-flex align-items-stretch">
          <SCardSku
            v-for="box in boxes"
            :key="box.id"
            :box="box"
            @deleted="skuDeleted"
            deletable
            editable
            :showItem="false"
            showStatusBox
            :removable="false"
            class="my-3 mx-3"
            @click.native="selectBox(box)"
            style="cursor: pointer; width: 210px"
            :class="{ 'border-primary': box.id == boxIdSelected }"
            mainQuantityDescription="Number of boxes"
          />
          <SButtonLoadmore store="warehouse.boxes" />
          <div class="btn-add-sku my-3 mx-3">
            <CButton
              :disabled="boxes_quantity >= sfa_selected.quantity"
              class="w-100 h-100"
              @click="showModalCreateSKU = true"
            >
              <TMessage
                :content="`New SKU`"
                alignment="center"
                color="primary"
                bold
                class="m-0"
              >
                <template #prefix>
                  <CIcon name="cis-plus" class="mr-1" />
                </template>
                <template #suffix>
                  {{ `(${boxes_quantity}/${sfa_selected.quantity})` }}
                </template>
              </TMessage>
            </CButton>
          </div>
        </CRow>
        <CardBoxDetail
          class="mt-3"
          v-if="boxSelected"
          removable
          :box="boxSelected"
          @updated="fetchBoxes()"
          editable
          :disabled="showModalCreateSKU"
        />
        <SModalCreateSku
          v-if="sfa_selected.id"
          :sfa_id="sfa_selected.id"
          :show.sync="showModalCreateSKU"
          @created="skuCreated"
          :purchaseItems="sfa_selected.purchase_items"
          @packed="packed"
          :tracking_code="sfa_selected.tracking_code"
        />
        <TModalConfirm
          :show.sync="showModalWarning"
          @click-confirm="swapSFA(sfaWillMove)"
          title="Tally"
        >
          <template #content>
            <div class="d-flex h5">
              <TMessage content="There are" />
              <CBadge color="danger" class="h6 mx-1">
                {{ sfa_selected.quantity - boxes_quantity }}
              </CBadge>
              <TMessage content="packages left untested" />
            </div>
            <TMessage content="Do you want to change the page?" size="h5" />
          </template>
        </TModalConfirm>
      </div>

      <CCard v-if="!sfa_id_selected">
        <CCardHeader class="d-flex flex-wrap align-items-center">
          <TMessage content="Create new SFA" bold />
          <TInputCheckbox
            class="ml-auto"
            label="Print labels after creating SFA"
            :checked="labelPrint"
            @change="labelPrint = !labelPrint"
          />
        </CCardHeader>
        <CCardBody>
          <form>
            <CRow>
              <CCol col="12" lg="7">
                <SInputTracking
                  @keydown.enter.native="focusNext($event, 4)"
                  @created="trackingCreated"
                  size="lg"
                  :value.sync="data.tracking_code"
                  @update:value="data.tracking_code = $event"
                />
                <SSelectCurrencyBox
                  @keydown.enter.native="focusNext"
                  label="Currency"
                  :value.sync="data.currency_id"
                  :prepend="[]"
                  class="my-3 col-form-label-lg"
                />
              </CCol>
              <CCol col="12" lg="5" class="mb-3">
                <TInputNumber
                  label="Total quantity"
                  @update:value="data.quantity = $event"
                  size="lg"
                  @keydown.enter.native="focusNext"
                />
                <TInputNumber
                  label="Shipping fee"
                  class="my-3"
                  size="lg"
                  @keydown.enter.native="focusNext"
                  @update:value="data.shipping_inside = $event"
                />
                <TInputText
                  label="Discount code"
                  class="my-3"
                  size="lg"
                  @keydown.enter.native="focusNext"
                  @update:value="data.coupon = $event"
                />
                <TInputDateTime
                  label="Arrival date"
                  size="lg"
                  class="my-3"
                  :value="data.arrival_date"
                  @keydown.enter.native="create"
                  @update:value="data.arrival_date = $event"
                />
              </CCol>
            </CRow>
          </form>
        </CCardBody>
        <CCardFooter>
          <div class="float-right">
            <TButton
              content="Next to input SKUs"
              :disabled="creating"
              @click="create"
              icon="cil-arrow-right"
              size="lg"
            />
          </div>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import barcode from "../../mixins/barcode";
import filterable from "../../mixins/filterable";
import label from "../../mixins/label";
import { mapGetters } from "vuex";
import CardBoxDetail from "../../components/CardBoxDetail.vue";
import FilterForSfa from "../../components/FilterForSfa.vue";
import warehouseScope from "@/core/services/tomoni/warehouse_scope.local";

export default {
  components: { CardBoxDetail, FilterForSfa },
  mixins: [filterable, label, barcode],
  data() {
    return {
      data: {
        currency_id: "VND",
        tracking_code: "",
      },
      showModalCreateSKU: false,
      showModalWarning: false,
      sfaWillMove: "",
      palletWillMove: false,
      sfaFields: [
        { key: "tracking_code", label: "Tracking" },
        { key: "quantity", label: "Total quantity" },
        { key: "shipping_inside", label: "Shipping fee" },
        { key: "coupon", label: "Coupon" },
        { key: "arrival_date", label: "Arrival date" },
      ],
      items: [],
      product_id: "",
      boxSelected: null,
      boxIdSelected: null,
      fileRecords: [],
      fileRecordsForUpload: [], // maintain an upload queue
      purchaseItems: [],
    };
  },
  created() {
    this.$store.commit("warehouse.boxes.purge");
  },
  mounted() {
    if (this.lodash.isEmpty(this.query)) {
      this.$store.dispatch("warehouse.sfas.fetch.if-first-time");
    } else this.$store.dispatch("warehouse.sfas.apply-query", this.query);
  },
  computed: {
    ...mapGetters({
      isConnected: "printer.isConnected",
      printer: "printer.value",
    }),
    creating() {
      return this.$store.getters["warehouse.sfas.creating"];
    },
    list_fetching() {
      return this.$store.getters["warehouse.sfas.fetching"];
    },
    sfas() {
      return this.$store.getters["warehouse.sfas.list"];
    },
    sfa_id_selected() {
      return this.$route.query.sfa_id;
    },
    sfa_selected() {
      return this.$store.getters["warehouse.sfas.detail"];
    },
    detail_loading() {
      return (
        this.$store.getters["warehouse.sfas.detail.loading"] ||
        this.$store.getters["warehouse.sfa_receipts.detail.loading"] ||
        this.$store.getters["warehouse.sfa_receipts.loading"] ||
        this.$store.getters["warehouse.boxes.detail.loading"] ||
        this.$store.getters["warehouse.boxes.loading"]
      );
    },
    boxes() {
      return this.$store.getters["warehouse.boxes.list"];
    },
    receipts() {
      return this.$store.getters["warehouse.sfa_receipts.list"];
    },
    boxes_quantity() {
      return this.lodash.sumBy(this.boxes, "duplicate");
    },
    warehouse_scope() {
      return warehouseScope.get();
    },
  },
  watch: {
    sfa_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store
            .dispatch("warehouse.sfas.detail.fetch", id)
            .then((data) => {
              if (data.received_quantity < data.quantity) {
                this.showModalCreateSKU = true;
              }
            });
          this.data.tracking_code = "";
          this.fetchBoxes();
          this.fetchReceipts();
        }
      },
    },
    receipts(rs) {
      this.fileRecords = rs.map((receipt) => {
        return {
          receipt_id: receipt?.id,
          name: receipt.path_file,
          size: 1,
          type: receipt?.info_file?.mime_type || "image",
          ext: "jpg",
          url:
            process.env.VUE_APP_ORDER_SERVICE_HOST +
            "/files/" +
            receipt.path_file,
        };
      });
    },
    boxes(boxes) {
      this.boxSelected = boxes.find((x) => x.id == this.boxIdSelected);
    },
    boxIdSelected() {
      this.items = [];
    },
    warehouse_scope: {
      immediate: true,
      handler(scope) {
        switch (scope) {
          case "tochigi-jp":
            this.data.currency_id = "JPY";
            break;
          case "matsudo-jp":
            this.data.currency_id = "JPY";
            break;
          default:
            this.data.currency_id = "VND";
            break;
        }
      },
    },
  },
  methods: {
    pickBarcode(e) {
      const code = this.press(e);
      if (this.sfa_id_selected) return;
      if (code) {
        this.data.tracking_code = code;
      }
    },
    fetchBoxes() {
      this.$store.dispatch("warehouse.boxes.apply-query", {
        "filter[sfa_id]": this.sfa_id_selected,
      });
    },
    fetchReceipts() {
      this.$store.dispatch("warehouse.sfa_receipts.apply-query", {
        "filter[sfa_id]": this.sfa_id_selected,
      });
    },
    create() {
      this.$store.dispatch("warehouse.sfas.create", this.data).then((sfa) => {
        const data = {
          ...this.message,
          type: "SFA",
          sfaId: sfa.id,
          tracking: sfa.tracking_code,
          sfaQuantity: sfa.quantity,
          pageSize: "1",
        };
        if (this.labelPrint) {
          this.$store.dispatch("printer.send-message", JSON.stringify(data));
        }
        this.$router.push({ query: { sfa_id: sfa.id } });
        this.showModalCreateSKU = true;
        this.data = this.lodash.pick(this.data, "currency_id");
      });
    },
    skuCreated(data) {
      this.selectBox(data);
      this.showModalCreateSKU = false;
      this.$store.dispatch("warehouse.boxes.fetch");
    },
    skuDeleted() {
      this.fetchBoxes();
    },
    updateSFA($field, $value) {
      this.$store.dispatch("warehouse.sfas.detail.update", {
        [$field]: $value,
      });
    },

    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);

      /**
       * batch upload
       */
      this.fileRecordsForUpload.forEach((fileRecordForUpload, index) => {
        const file = fileRecordForUpload.file;
        this.lodash.compressor(file, 0.4).then((result) => {
          this.$store
            .dispatch("warehouse.sfa_receipts.create", {
              sfa_id: this.sfa_id_selected,
              file: result,
            })
            .then(() => {
              this.fileRecordsForUpload.splice(index, 1);
            });
        });
      });
    },
    onBeforeDelete: function (fileRecord) {
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
    },
    fileDeleted: function (fileRecord) {
      this.$store.dispatch("warehouse.sfa_receipts.delete", fileRecord.receipt_id);
    },
    focusNext(e, number = 1) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + number].focus();
      }
    },
    trackingCreated(tracking) {
      const message = {
        ...this.message,
        type: "TRACKING",
        tracking: tracking.id,
        sfaQuantity: 0,
        quantity: 2,
        pageSize: "1", Barcode: "1", map: "1", place: "2", region: "Chiba",
      };
      this.$store.dispatch("printer.send-message", JSON.stringify(message));
    },
    selectBox(box) {
      this.boxSelected = box;
      this.boxIdSelected = box.id;
    },
    checkSFA() {
      if (
        this.sfa_id_selected &&
        this.sfa_selected.quantity - this.boxes_quantity
      ) {
        this.showModalWarning = true;
      } else
        this.lodash.appendRouteQuery(this.$router, {
          query: { action: "new_sfa" },
        });
    },
    checkSwapSFA(sfa) {
      if (sfa.id == this.sfa_id_selected) return;
      this.sfaWillMove = sfa.id;
      if (
        this.sfa_id_selected &&
        this.sfa_selected.quantity - this.boxes_quantity
      ) {
        this.showModalWarning = true;
      } else {
        this.swapSFA(sfa.id);
      }
    },
    swapSFA(id) {
      this.lodash
        .appendRouteQuery(this.$router, {
          query: { sfa_id: id },
        })
        .then(() => {
          this.sfaWillMove = "";
        });
    },
    packed(data, itemsQuantity) {
      const message = {
        ...this.message,
        type: "SKU",
        sfaId: data.sfa_id,
        sku: data.id,
        quantity: data.duplicate,
        itemQuantity: itemsQuantity,
        pageSize: "1", Barcode: "1", map: "1", place: "2", region: "Chiba",
      };
      this.$store.dispatch("printer.send-message", JSON.stringify(message));
    },
  },
};
</script>
